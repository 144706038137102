<template>
  <v-app id="app">
    <v-main class="mb-6 pa-0 mt-n15">
      <v-row class="d-flex flex-row transparent">
        <v-col cols="7" class="d-flex flex-row justify-center transparent">
          <v-card class="d-flex flex-column justify-center" flat>
            <v-card elevation="0" class="mt-n15">
              <v-stepper v-model="stp" class="elevation-0">
                <v-stepper-header>
                  <v-stepper-step :complete="stp > 1" step="1" :rules="[errorId]">
                    Identificador do Profissional
                    <small>{{ errorMessageId }}</small>
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="2" :rules="[errorPIN]">
                    Código de Acesso
                    <small>{{ errorMessagePIN }}</small>
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-text-field-cpf class="mt-1" v-model="id" v-bind:label="label" v-bind:properties="{
                      required: true,
                      rules: [
                        (v) =>
                          !!v ||
                          'O número do cpf do profissional é obrigatório para acessar o sistema',
                      ],
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      clearable: true,
                      placeholder: 'Digite seu cpf',
                    }" v-bind:options="{
  outputMask: '###########',
  empty: null,
  applyAfter: false,
}" v-on:focus="focusId()" />
                    <!--v-text-field
                      :success="successId"
                      class="mt-2"
                      label=""
                      placeholder="Identificador do Profissional"
                      hint="Digite seu cpf"
                      v-model="id"
                      outlined
                      @focus="focusId()"
                      @keydown.enter.prevent="verifyUser()"
                    ></v-text-field-->

                    <v-btn class="mt-2" color="primary" @click="verifyUser()">
                      <v-icon> mdi-login-variant </v-icon>
                      Verificar
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <div class="ma-auto position-relative" style="max-width: 300px">
                      <v-otp-input length="5" v-model="pin" :disabled="loading" @finish="login"></v-otp-input>
                    </div>
                    <v-btn text @click="cancel()"> Cancel </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card class="pa-15" color="grey lighten-4 mb-n10 mt-n4 ml-15" :min-height="screenHeight" max-width="580"
            flat tile elevation="24">
            <v-card-title class="d-flex justify-center align-self-center">
              <v-img src="~@/assets/logo/logo.png" max-height="250" max-width="250"></v-img>
            </v-card-title>
            <v-card-title class="titleCard d-flex justify-center font-weight-thin grey--text text--darken-1">
              CONFIGURAÇÃO INTEGRADA DE eHEALTH
            </v-card-title>
            <v-card-title class="titleSystem d-flex justify-center grey--text text--darken-0">
              <font-awesome-icon :icon="['fat', 'brain-circuit']" fixed-width style="font-size: 45px" />
            </v-card-title>
            <v-card-title class="titleSystem d-flex justify-center font-weight-thin grey--text text--darken-1">
              Gestão do Sistema de Inteligência em Saúde - v {{
                sisVersion.version.major + "." +
                  sisVersion.version.minor
                  + "."
                  + sisVersion.version.patch
              }}
              <p class="titleBuild text-center grey--text text--lighten-1 caption">
                Last build: {{ sisVersion.build.date }}
              </p>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>



<script>
import { auth, functions } from "../firebase";

export default {
  data() {
    return {
      label: "Identificador do Profissional",
      loading: false,
      ui: null,
      id: null,
      stp: 1,
      pin: null,
      successId: false,
      errorMessageId: null,
      noErrorId: true,
      errorMessagePIN: null,
      noErrorPIN: true,
      sisVersion: null,
      screenHeight: 0,
    };
  },
  methods: {
    focusId() {
      this.noErrorId = true;
      this.errorMessageId = null;
    },
    focusPIN() {
      this.noErrorPIN = true;
      this.errorMessagePIN = null;
    },
    errorId() {
      return this.noErrorId;
    },
    errorPIN() {
      return this.noErrorPIN;
    },
    cancel() {
      this.loading = false;
      this.stp = 1;
      this.pin = null;
      this.id = null;
      this.successId = false;
      this.errorMessageId = null;
      this.noErrorId = true;
      this.errorMessagePIN = null;
      this.noErrorPIN = true;
    },
    verifyUser() {
      let sisLoginPinCreate = functions.httpsCallable(
        "sis-login-pin-professional-create-api"
      );
      sisLoginPinCreate({
        professionalId: this.id,
      })
        .then((result) => {
          this.successId = true;
          this.stp = 2;
        })
        .catch((error) => {
          this.pin = null;
          this.errorMessageId = error.message;
          this.noErrorId = false;
          console.error(error);
        });
    },
    login() {
      this.loading = true;
      let sisLoginPinValidation = functions.httpsCallable(
        "sis-login-pin-professional-validation-api"
      );
      sisLoginPinValidation({
        professionalId: this.id,
        pin: this.pin,
      })
        .then((result) => {
          auth
            .signInWithCustomToken(result.data.uidToken)
            .then((userCredential) => {
              userCredential.user
                .getIdTokenResult()
                .then(async (idTokenResult) => {
                  if (idTokenResult.claims.professional) {
                    await this.$store.dispatch(
                      "fetchProfessional",
                      result.data.professional
                    );
                    this.$router.replace({
                      name: "Patients",
                    });
                  } else {
                    this.messageError =
                      "Profissional não autorizado na plataforma.";
                    this.noError = false;
                  }
                });
            })
            .catch((error) => {
              this.loading = false;
              this.pin = null;
              this.errorMessagePIN = error.message;
              this.noErrorPIN = false;
              console.error(error);
            });
        })
        .catch((error) => {
          this.loading = false;
          this.pin = null;
          this.errorMessagePIN = error.message;
          this.noErrorPIN = false;
          console.error(error);
        });
    },
  },
  created() {
    this.sisVersion = this.$sisVersion;
    this.screenHeight = screen.height;
  },
};

</script>

<style scoped>
.titleCard {
  font-size: 30px !important;
  line-height: 1.6;
}

.titleSystem {
  font-size: 16px !important;
  line-height: 1.4;
}

.titleBuild {
  font-size: 10px !important;
  line-height: 1.6;
}

.v-stepper__header {
  box-shadow: none;
}
</style>
