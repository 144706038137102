import {db, functions, firebase} from "../../firebase";
import {firestoreAction} from "vuexfire";

export default {
    state: {
        professional: {},
        menu: [],
        items: []
    },
    mutations: {
        SET_PROFESSIONAL(state, value) {
            state.professional = value;
        },
        SET_MENU(state, value) {
            state.menu = value;
        },
        SET_ITEM(state, value) {
            state.items = value;
        }
    },
    actions: {
        async fetchProfessional(context, professional) {
            context.dispatch("fetchConfig");

            let professionalRef = db.collection("professionals").doc(professional.id);

            let profileRef = db.collection("profiles").where("queryId", "==", professional.configProfile);

            let profileData = await profileRef.get();
            let profileDoc = profileData.docs[0].data();

            let _menus = [];
            for (let index = 0; index < profileDoc.menus.length; index++) {
                if (profileDoc.menus[index].active) {
                    _menus.push(profileDoc.menus[index]);
                }
            }
            context.commit("SET_MENU", _menus);

            let _items = [];
            for (let index = 0; index < profileDoc.items.length; index++) {
                if (profileDoc.items[index].active) {
                    _items.push(profileDoc.items[index]);
                }
            }
            context.commit("SET_ITEM", _items);

            context.commit("SET_PROFESSIONAL", professional);
        }
    },
    getters: {
        professional(state) {
            return state.professional;
        },
        menu(state) {
            return state.menu;
        },
        items(state) {
            return state.items;
        },
        evaluators(state) {
            return state.evaluators;
        }
    }
};
