<template>
  <v-card color="transparent" flat tile>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>
    <Menu></Menu>
    <v-card-title class="mt-n4 ml-n4 mb-n8">
      <v-chip class="" small color="secondary" label>
        <span class="caption">
          Total de Pacientes: {{ customersIndicators.total }}</span
        >
      </v-chip>
      <v-spacer></v-spacer>
      <v-btn
        @click="downloadPatients()"
        class="mr-4"
        elevation="0"
        color="secondary"
        min-width="0"
        min-height="0"
        max-height="30"
        :disabled="!initial"
      >
        Baixar
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-switch
        flat
        color="secondary"
        @change="getCustomers()"
        inset
        class="caption"
        v-if="professional.dev"
        x-small
        v-model="developmentMode"
        :label="`Modo desenvoledor: ${developmentMode.toString()}`"
      ></v-switch>
    </v-card-title>
    <v-card-title class="mt-n10">
      <v-btn-toggle color="deep-purple" v-model="initial" class="ml-n4 mr-2">
        <v-btn min-height="30" min-width="30" small text value="A">A</v-btn>
        <v-btn min-height="30" min-width="30" small text value="B">B</v-btn>
        <v-btn min-height="30" min-width="30" small text value="C">C</v-btn>
        <v-btn min-height="30" min-width="30" small text value="D">D</v-btn>
        <v-btn min-height="30" min-width="30" small text value="E">E</v-btn>
        <v-btn min-height="30" min-width="30" small text value="F">F</v-btn>
        <v-btn min-height="30" min-width="30" small text value="G">G</v-btn>
        <v-btn min-height="30" min-width="30" small text value="H">H</v-btn>
        <v-btn min-height="30" min-width="30" small text value="I">I</v-btn>
        <v-btn min-height="30" min-width="30" small text value="J">J</v-btn>
        <v-btn min-height="30" min-width="30" small text value="K">K</v-btn>
        <v-btn min-height="30" min-width="30" small text value="L">L</v-btn>
        <v-btn min-height="30" min-width="30" small text value="M">M</v-btn>
        <v-btn min-height="30" min-width="30" small text value="N">N</v-btn>
        <v-btn min-height="30" min-width="30" small text value="O">O</v-btn>
        <v-btn min-height="30" min-width="30" small text value="P">P</v-btn>
        <v-btn min-height="30" min-width="30" small text value="Q">Q</v-btn>
        <v-btn min-height="30" min-width="30" small text value="R">R</v-btn>
        <v-btn min-height="30" min-width="30" small text value="S">S</v-btn>
        <v-btn min-height="30" min-width="30" small text value="T">T</v-btn>
        <v-btn min-height="30" min-width="30" small text value="U">U</v-btn>
        <v-btn min-height="30" min-width="30" small text value="V">V</v-btn>
        <v-btn min-height="30" min-width="30" small text value="W">W</v-btn>
        <v-btn min-height="30" min-width="30" small text value="X">X</v-btn>
        <v-btn min-height="30" min-width="30" small text value="Y">Y</v-btn>
        <v-btn min-height="30" min-width="30" small text value="Z">Z</v-btn>
      </v-btn-toggle>
      <v-text-field
        color="purple"
        dense
        outlined
        v-model="search"
        append-icon="mdi-magnify"
        label="Nome, contato ou identificador do paciente"
        single-line
        hide-details
        clearable
        class="mr-n4 caption"
      >
      </v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="customers"
      :search="search"
      :items-per-page="14"
      :sort-by="['fullName']"
      :sort-desc="[false]"
      :calculate-widths="true"
      :loading="loadingCustomers"
    >
      <template v-slot:[`item.userId`]="{ item }">
        <span class="caption">{{ item.userId }}</span>
      </template>

      <template v-slot:[`item.dev`]="{ item }">
        <v-switch
          flat
          color="pink"
          @change="setDevelopmentMode(item)"
          inset
          class="caption"
          x-small
          v-model="item.dev"
          :label="item.dev ? 'DESENVOLVEDOR' : 'PACIENTE'"
        ></v-switch>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-switch
          flat
          color="success"
          @change="activePatient(item)"
          inset
          class="caption"
          x-small
          v-model="item.active"
          :label="item.active ? 'ATIVADO' : 'DESATIVADO'"
        ></v-switch>
      </template>

      <template v-slot:[`item.age`]="{ item }">
        <span class="caption">{{
          formatBirthdate(item.birthdate) | moment("from", "now", true)
        }}</span>
      </template>
      <template v-slot:[`item.gender`]="{ item }">
        <span class="caption">{{ item.gender }}</span>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span class="caption">{{ formatPhone(item.phone) }}</span>
      </template>
      <template v-slot:[`item.features`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="remove(item)"
              class="mx-1"
              v-bind="attrs"
              small
              text
              v-on="on"
              elevation="0"
              color="error"
              min-width="0"
              max-width="30"
              min-height="0"
              max-height="30"
            >
              <font-awesome-icon
                :icon="['fal', 'trash']"
                fixed-width
                style="font-size: 24px"
              />
            </v-btn>
          </template>
          <span>Remover Paciente</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        <span class="caption">{{ item.fullName }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { formatToCPF, formatToPhone } from "brazilian-values";
import { db } from "../../../firebase";
import Menu from "./menu/Menu";
import { json2csv } from "json-2-csv";
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      search: "",
      developmentMode: false,
      initial: null,
      overlay: false,
      loading: false,
      headers: [
        { text: "Ativo", value: "active" },
        { text: "Modo Desenvolvedor", value: "dev" },
        { text: "Nome", value: "fullName" },
        { text: "Sexo", value: "gender" },
        { text: "Idade", value: "age" },
        { text: "Nascimento", value: "birthdate" },
        { text: "Contato", value: "phone" },
        { text: "Identificador", value: "userId" },
        { text: "Ações", value: "features", sortable: false },
      ],
    };
  },
  components: {
    Menu,
  },
  computed: {
    ...mapGetters({
      customers: "customers",
      professional: "professional",
      customersIndicators: "customersIndicators",
      loadingCustomers: "loadingCustomers",
    }),
  },
  watch: {
    async initial(letter) {
      if (letter) {
        this.getCustomers();
      } else {
        await this.$store.dispatch("unbindCustomers");
      }
    },
  },
  methods: {
    async downloadPatients() {
      let options = {
        delimiter: {
          wrap: '"', // Double Quote (") character
          field: ";", // Comma field delimiter
          eol: "\n", // Newline delimiter
        },
        prependHeader: true,
        sortHeader: false,
        excelBOM: true,
        trimHeaderValues: true,
        trimFieldValues: true,
        expandArrayObjects: true,
        useDateIso8601Format: true,
        excludeKeys: [
          "gender",
          "phoneCode",
          "initial",
          "userId",
          "middleName",
          "name",
          "username",
          "accessCode",
          "serviceProfile",
          "lastName",
          "healthcarePayercode",
          "occupationalData.occupationalData",
          "occupationalData.company",
          "occupationalData.companyName",
          "occupationalData.cnpj",
          "occupationalData.cbo",
          "occupationalData.department",
          "occupationalData.ctpsSeries",
          "occupationalData.gfip",
          "occupationalData.ctps",
          "occupationalData.ctpsUf",
          "occupationalData.pisPasep",
          "occupationalData.jobTitle",
          "healthcarePayer.network",
          "healthcarePayer.accommodation",
          "healthcarePayer.segmentation",
          "healthcarePayer.geography",
          "healthcarePayer.date",
          "healthcarePayer.name",
        ],
        //keys: ['paciente', 'enviado', 'respondido', 'origem']
      };

      let json2csvCallback = function (err, csv) {
        if (err) throw err;
        var blob = new Blob([csv], { type: ".csv;charset=utf-8" });
        let time = new Date().toUTCString();
        saveAs(blob, "pacientes_" + time + ".csv");
      };

      json2csv(this.customers, json2csvCallback, options);
    },
    async setDevelopmentMode(customer) {
      this.overlay = true;
      let customerRef = db.collection("customer").doc(customer.id);
      await customerRef.update({ dev: customer.dev }, { merge: true });
      this.overlay = false;
    },
    async activePatient(customer) {
      this.overlay = true;
      let customerRef = db.collection("customer").doc(customer.id);
      await customerRef
        .update({ active: customer.active }, { merge: true })
        .then(async () => {
          let customerMapRef = db
            .collection("customer-map")
            .doc(customer.userId);
          await customerMapRef.update(
            { active: customer.active },
            { merge: true }
          );
          this.overlay = false;
        });
    },
    async getCustomers() {
      await this.$store.dispatch("unbindCustomers");
      if (this.initial) {
        await this.$store.dispatch("bindCustomers", {
          initial: this.initial,
          developmentMode: this.developmentMode,
        });
      }
    },
    formatBirthdate(birthdate) {
      return this.$moment(birthdate, "DD/MM/YYYY");
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    formatCPF(item) {
      return formatToCPF(item);
    },
    formatPhone(item) {
      return formatToPhone(item);
    },
    async remove(customer) {
      console.info(customer);
    },
  },
  async created() {
    await this.$store.dispatch("bindCustomersIndicators");
    await this.$store.dispatch("bindLoads");
    if (this.professional.dev) {
      this.developmentMode = true;
    }
  },
};
</script>
<style>
</style>