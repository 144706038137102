<template>
  <v-card flat class="mb-4">
    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon :icon="['fal', 'hospital-user']" fixed-width style="font-size: 20px" class="mr-2" />Pacientes
      </v-card-title>
      <v-spacer></v-spacer>
      <router-link v-if="$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
        <v-btn class="secondary--text" small text elevation="0" color="primary" min-width="40" max-width="30"
          min-height="30" max-height="30">
          <font-awesome-icon :icon="['fal', 'reply']" fixed-width style="font-size: 25px" />
        </v-btn>
      </router-link>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col><v-file-input v-model="file" show-size @change="getFileObject" label="Pacientes" outlined
            :disabled="loads.length > 0">
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template></v-file-input></v-col>
        <v-col><v-text-field label="Número de pacientes" outlined disabled v-model="size"></v-text-field></v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-progress-linear class="mt-n8" rounded v-model="load" color="secondary" height="30">
        <template v-slot:default="{ value }">
          <strong class="caption">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </v-card-text>
    <v-card-text class="mt-n4">
      <v-toolbar dense rounded outlined elevation="0" class="mb-4">
        <v-card-title class="ml-n6 grey--text text--darken-2">
          Carregar Pacientes
        </v-card-title>
        <v-switch flat color="pink" inset class="caption mt-6" x-small v-model="dev"
          :label='dev ? "DESENVOLVEDOR" : "PACIENTE"'></v-switch>
        <v-switch flat color="success" inset class="caption ml-4 mt-6" x-small v-model="active"
          :label='active ? "ATIVADO" : "DESATIVADO"'></v-switch>
        <v-spacer></v-spacer>
        <v-btn outlined class="" v-bind="attrs" v-on="on" dark color="primary" elevation="0" @click="loadCustomers()">
          Carregar
        </v-btn>
        <v-btn outlined class="ml-2" v-bind="attrs" v-on="on" dark color="error" elevation="0" @click="deleteAll()">
          Apagar Todos
        </v-btn>
        <v-btn @click="download()" class="ml-2 mr-n2" v-bind="attrs" v-on="on" elevation="0" color="primary"
          min-width="0" max-width="40" min-height="0" max-height="40">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table class="elevation-0" :headers="headers" :items="loads" :items-per-page="50" :sort-by="['createdAt']"
        :sort-desc="[false]" calculate-widths="true">
        <template v-slot:[`item.error`]="{ item }">
          <v-chip x-small label v-if="item.error" class="red white--text">
            {{ item.error }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="deleteCustomer(item)" class="ml-1" v-bind="attrs" v-on="on" elevation="0" color="warning"
            min-width="0" max-width="40" min-height="0" max-height="40">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <userId :customer="item"></userId>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text>
      <v-toolbar dense rounded outlined elevation="0" class="mb-4">
        <v-card-title class="ml-n6 grey--text text--darken-2">
          Executar
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn outlined class="ml-2" dark color="primary" elevation="0" @click="execute()">
          Criar
        </v-btn>
        <v-btn outlined class="ml-2 mr-n2" dark color="error" elevation="0" @click="update()">
          Atualizar
        </v-btn>
      </v-toolbar>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db, functions } from "../../../firebase";
import csvDownload from 'json-to-csv-export';
const csvToJson = require('csvtojson');
import userId from './validation/userId.vue'

export default {
  components: {
    userId
  },
  data() {
    return {
      file: null,
      size: null,
      index: 0,
      length: 0,
      load: 0,
      dev: false,
      active: true,
      headers: [
        { text: "Ativo", value: "active", sortable: false },
        { text: "Desenvolvedor", value: "dev", sortable: false },
        { text: "Nome", value: "fullName" },
        { text: "Sexo", value: "gender" },
        { text: "Contato", value: "phone" },
        { text: "Data Nascimento", value: "birthdate" },
        { text: "Identificador", value: "userId", sortable: false },
        { text: "Status", value: "status" },
        { text: "Erro", value: "error" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loads: "loads",
      config: "config"
    }),
  },
  watch: {},
  methods: {
    async deleteAll() {
      let loadRef = db.collection('load');
      let loadDocs = await loadRef.get();

      for (let idx = 0; idx < loadDocs.docs.length; idx++) {
        await loadDocs.docs[idx].ref.delete();
      }
    },
    async enrichCustomer(customer) {
      customer.dev = this.dev
      customer.active = this.active
      customer.fullName = customer.fullName.toLowerCase();
      let words = customer.fullName.split(" ");
      customer.fullName = words.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      }).join(" ");

      if (this.config.phoneAuthentication) {
        customer.userId = '55' + String(customer.phone).replace(/-/g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/ /g, "");
      } else {
        customer.userId = customer.userId.replace(/\./g, "").replace(/-/g, "");
      }

      if (customer.gender == 'F') {
        customer.gender = 'Feminino'
      } else if (customer.gender == 'M') {
        customer.gender = 'Masculino'
      }
    },
    download() {
      const dataToConvert = {
        data: this.loads,
        filename: 'load_report',
        delimiter: ';',
      }
      csvDownload(dataToConvert);
    },
    deleteCustomer(item) {
      db.collection("load")
        .doc(item.id)
        .delete();
    },
    updateLoad() {
      this.load = (this.index++ / this.length) * 100;
    },
    resetLoad(index, length) {
      this.load = 0;
      this.index = index;
      this.length = length;
    },
    getFileObject() {
      let reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = () => {
        csvToJson({
          noheader: false,
          trim: true,
          delimiter: ";",
          headers: ['fullName', 'phone', 'gender', 'birthdate', 'cpf', 'userId']
        })
          .fromString(reader.result)
          .then((jsonFile) => {
            this.customers = jsonFile;
            console.info(this.customers);
            this.size = this.customers.length;
          })
      };
    },
    async loadCustomers() {
      this.resetLoad(1, this.customers.length);
      for (let loadedCustomer of this.customers) {
        this.enrichCustomer(loadedCustomer);
        await db
          .collection("load")
          .add(loadedCustomer)
          .then(() => {
            this.updateLoad();
          });
      }
    },

    async update() {
      let current_loads = await db.collection("load").get();
      this.resetLoad(1, current_loads.size);
      for (let index = 0; index < current_loads.size; index++) {
        let customer = current_loads.docs[index];
        let loadRef = db.collection("load").doc(customer.id);

        let customerMapRef = db.collection("customer-map").doc(customer.data().userId);
        let customerMapDoc = await customerMapRef.get();
        let customerMapData = customerMapDoc.data();

        let customerRef = db.collection("customer").doc(customerMapData.customer);
        await customerRef.update({ active: customer.data().active, dev: customer.data().dev }, { merge: true }).then(async () => {
          await customerMapRef.update({ active: customer.data().active }, { merge: true });
          await db.collection("load")
            .doc(loadRef.id)
            .delete();
          this.updateLoad();
        })
      }
    },
    async execute() {
      let current_loads = await db.collection("load").get();
      this.resetLoad(1, current_loads.size);
      for (let index = 0; index < current_loads.size; index++) {
        let customer = current_loads.docs[index];
        let loadRef = db.collection("load").doc(customer.id);

        let customerNew = functions.httpsCallable("sis-customer-new-api");
        await customerNew({
          customer: customer.data(),
        })
          .then((result) => {
            db.collection("load")
              .doc(loadRef.id)
              .delete()
              .then(() => {
                return;
              });
          })
          .catch((err) => {
            loadRef.update({
              error: err.message
            }, { merge: true });
            console.error("O telefone não é mobile", err.message);
          });
        this.updateLoad();
      }
    },
  },
  created() { },
};
</script>

<style>
.height {
  height: 650px;
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
