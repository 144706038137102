import Vue from "vue";
import Vuex from "vuex";
import {vuexfireMutations, firestoreOptions} from "vuexfire";

import professional from "./modules/professional";
import customers from "./modules/customers";
import tcles from "./modules/tcles";
import companies from "./modules/companies";
import config from "./modules/config";
import load from "./modules/load";

firestoreOptions.wait = true;

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        professional,
        customers,
        config,
        tcles,
        companies,
        load
    },

    mutations: {
        ...vuexfireMutations
    }
});

export default store;
