<template>
  <v-card flat class="mb-4">
    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <font-awesome-icon :icon="['fal', 'hospital-user']" fixed-width style="font-size: 20px" class="mr-2" />Novo
        Paciente
      </v-card-title>
      <v-spacer></v-spacer>
      <router-link v-if="$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
        <v-btn class="secondary--text" small text elevation="0" color="primary" min-width="40" max-width="30"
          min-height="30" max-height="30">
          <font-awesome-icon :icon="['fal', 'reply']" fixed-width style="font-size: 25px" />
        </v-btn>
      </router-link>
      <v-btn class="mr-2" v-bind="attrs" v-on="on" dark text color="secondary" elevation="0" @click="addCustomer()">
        <v-icon dark>mdi-plus</v-icon> Adicionar
      </v-btn>
      <v-btn class="mr-n2" text color="warning" elevation="0" @click="$router.replace({ name: 'Patients' })">CANCELAR
      </v-btn>
    </v-toolbar>

    <v-card class="mx-auto mt-4" flat max-width="99%">
      <v-tabs v-model="tab" icons-and-text class="rounded" height="60">
        <v-tabs-slider color="warning"></v-tabs-slider>
        <v-tab>Geral</v-tab>
        <v-tab>Documentos</v-tab>
        <v-tab>Endereço</v-tab>
        <v-tab>Ocupacional</v-tab>
        <v-tab>Plano de Saúde</v-tab>
      </v-tabs>
      <v-form lazy-validation v-model="valid" ref="form">
        <v-tabs-items class="transparent" v-model="tab">
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field label="Nome completo" placeholder="Nome completo" outlined required :rules="[
                  (v) =>
                    !!v ||
                    'O nome do paciente é de preenchimento obrigatório',
                ]" v-model="fullName"></v-text-field>

                <v-text-field-simplemask v-model="phone" label="Celular" v-bind:properties="{
                  rules: [
                    (v) =>
                      !!v ||
                      'O número do celular do paciente é de preenchimento obrigatório',
                  ],
                  required: true,
                  prefix: '',
                  suffix: '',
                  readonly: false,
                  disabled: false,
                  outlined: true,
                  clearable: true,
                  placeholder: '',
                }" v-bind:options="{
  inputMask: '(##) #####-####',
  outputMask: '(##) #####-####',
  empty: null,
  applyAfter: false,
  alphanumeric: true,
  lowerCase: false,
}" />
                <v-select :items="genderOptions" label="Sexo" clearable outlined :rules="[
                  (v) =>
                    !!v ||
                    'O sexo do paciente é de preenchimento obrigatório',
                ]" required v-model="gender"></v-select>
                <v-text-field-simplemask v-model="birthdate" required label="Data nascimento" v-bind:properties="{
                  required: true,
                  rules: [
                    (v) =>
                      !!v ||
                      'A data de nascimento do paciente é de preenchimento obrigatório',
                  ],
                  prefix: '',
                  suffix: '',
                  readonly: false,
                  disabled: false,
                  outlined: true,
                  clearable: true,
                  placeholder: '',
                }" v-bind:options="{
  inputMask: '##/##/####',
  outputMask: '##/##/####',
  empty: null,
  applyAfter: false,
  alphanumeric: true,
  lowerCase: false,
}" />
                <v-text-field-simplemask v-if="!config.phoneAuthentication" v-model="userId"
                  label="Identificador do Paciente" v-bind:properties="{
                    prefix: '',
                    suffix: '',
                    required: true,
                    rules: [
                      (v) =>
                        (!!v && v.length <= config.idMaxSize) ||
                        'O número de identificação do paciente é de preenchimento obrigatório e pode ter no máximo ' +
                        config.idMaxSize +
                        ' caracteres.',
                    ],
                    readonly: false,
                    disabled: false,
                    outlined: true,
                    clearable: true,
                    placeholder: '',
                  }" v-bind:options="{
  inputMask: mask,
  outputMask: mask,
  empty: null,
  alphanumeric: false,
  applyAfter: false,
  lowerCase: false,
}" />
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field-simplemask v-model="cpf" label="CPF" v-bind:properties="{
                  required: false,
                  prefix: '',
                  suffix: '',
                  readonly: false,
                  disabled: false,
                  outlined: true,
                  clearable: true,
                  placeholder: '',
                }" v-bind:options="{
  inputMask: '###.###.###-##',
  outputMask: '###########',
  empty: null,
  applyAfter: false,
  alphanumeric: true,
  lowerCase: false,
}" />
                <v-text-field label="RG" placeholder="RG" outlined v-model="rg"></v-text-field>
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="8">
                    <v-text-field label="Rua" placeholder="" outlined v-model="customer.address" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field-integer v-model="addressNumber" label="Número" v-bind:properties="{
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      clearable: true,
                      placeholder: '',
                    }" v-bind:options="{
  inputMask: '#####',
  outputMask: '#####',
  empty: null,
  applyAfter: false,
}" />
                  </v-col>
                </v-row>
                <v-row class="mt-n6">
                  <v-col cols="4">
                    <v-text-field label="Complemento" placeholder="" outlined v-model="customer.addressComplement" />
                  </v-col>
                  <v-col cols="8">
                    <v-text-field label="Bairro" placeholder="" outlined v-model="customer.neighborhood" />
                  </v-col>
                </v-row>
                <v-row class="mt-n6">
                  <v-col cols="4">
                    <v-select :items="states" item-text="name" item-value="code" label="UF" clearable outlined
                      v-model="customer.state"></v-select>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field label="Cidade" placeholder="" outlined v-model="customer.city" />
                  </v-col>
                </v-row>
                <v-text-field-cep v-model="zipCode" label="CEP" v-bind:properties="{
                  disabled: false,
                  outlined: true,
                  clearable: true,
                  placeholder: '',
                }" v-bind:options="{
  outputMask: '########',
  empty: null,
  applyAfter: true,
}" />
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-select :items="companies" item-text="name" item-value="name" label="Empresas" clearable outlined
                  v-model="company" :return-object="true"></v-select>
                <v-text-field label="Cargo" placeholder="" outlined v-model="customer.occupationalData.jobTitle">
                </v-text-field>
                <v-text-field label="Departamento" placeholder="" outlined
                  v-model="customer.occupationalData.department"></v-text-field>
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field label="Plano de Saúde" placeholder="" outlined v-model="customer.healthcarePayer.name">
                </v-text-field>
                <v-text-field label="Número do Beneficiário" placeholder="" outlined
                  v-model="customer.healthcarePayer.code"></v-text-field>
                <v-text-field label="Segmentação" placeholder="" outlined
                  v-model="customer.healthcarePayer.segmentation"></v-text-field>
                <v-text-field label="Acomodação" placeholder="" outlined
                  v-model="customer.healthcarePayer.accommodation"></v-text-field>
                <v-text-field label="Rede de Atendimento" placeholder="" outlined
                  v-model="customer.healthcarePayer.network"></v-text-field>
                <v-text-field label="Inclusão" placeholder="" outlined v-model="customer.healthcarePayer.date">
                </v-text-field>
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-5">
            <v-row>
              <v-col cols="6">
                <v-text-field :id="field" class="mb-2" v-for="field in this.customer.customFields" v-model="field.value"
                  :key="field.name" :hint="field.description" single-line outlined>
                  ''
                </v-text-field>
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
const states = require("../../../general/states.json");

export default {
  data() {
    return {
      mask: null,
      tab: null,
      customer: {},
      fullName: null,
      cpf: null,
      rg: null,
      phone: null,
      userId: null,
      gender: null,
      birthdate: null,
      states: states,
      addressNumbe: null,
      zipCode: null,
      company: null,
      genderOptions: [
        { text: "Masculino", value: "Masculino" },
        { text: "Feminino", value: "Feminino" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      companies: "companies",
      config: "config",
    }),
  },
  watch: {
    company(newValue, oldValue) {
      this.customer.occupationalData.cnpj = newValue.cnpj;
      this.customer.occupationalData.companyName = newValue.name;
      this.customer.occupationalData.company = newValue.id;
    },
    fullName(newValue, oldValue) {
      this.customer.fullName = newValue;
    },
    gender(newValue, oldValue) {
      this.customer.gender = newValue;
    },
    phone(newValue, oldValue) {
      this.customer.phone = newValue;
    },
    cpf(newValue, oldValue) {
      this.customer.cpf = newValue;
    },
    rg(newValue, oldValue) {
      this.customer.rg = newValue;
    },
    birthdate(newValue, oldValue) {
      this.customer.birthdate = newValue;
    },
    userId(newValue, oldValue) {
      this.customer.userId = newValue;
    },
    addressNumber(newValue, oldValue) {
      this.customer.addressNumber = newValue;
    },
    zipCode(newValue, oldValue) {
      this.customer.zipCode = newValue;
    },
  },

  methods: {
    async addCustomer() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.$swal
          .fire({
            title: "Adicionar Paciente",
            text:
              "Você realmente deseja adicionar o(a) paciente " +
              this.customer.fullName +
              "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              this.$swal.showLoading();
              this.$swal.getCancelButton().setAttribute("disabled", "");
              console.info(this.customer);
              let customerNew = functions.httpsCallable("sis-customer-new-api");
              await customerNew({
                customer: this.customer,
              })
                .then((result) => {
                  return;
                })
                .catch((error) => {
                  this.$swal.getCancelButton().setAttribute("enabled", "");
                  this.$swal.showValidationMessage(`Erro: ${error}`);
                  console.error(error.message);
                });
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal
                .fire({
                  icon: "success",
                  title: "Paciente criado com sucesso! ",
                  toast: false,
                  showConfirmButton: true,
                  timer: 1000,
                  timerProgressBar: true,
                })
                .then((result) => {
                  this.$router.replace({ name: "Patients" });
                });
            }
          });
      }
    },
  },
  async created() {
    await this.$store.dispatch("bindCompanies");
    this.mask = "";

    for (let index = 0; index < this.config.idMaxSize; index++) {
      this.mask += "#";
    }

    this.customer.occupationalData = {};
    this.customer.occupationalData.occupationalData = "";
    this.customer.occupationalData.department = "";
    this.customer.occupationalData.ctpsUf = "";
    this.customer.occupationalData.jobTitle = "";
    this.customer.occupationalData.cnpj = "";
    this.customer.occupationalData.ctpsSeries = "";
    this.customer.occupationalData.ctps = "";
    this.customer.occupationalData.pisPasep = "";
    this.customer.occupationalData.gfip = "";
    this.customer.occupationalData.companyName = "";
    this.customer.occupationalData.company = "";
    this.customer.occupationalData.cbo = "";

    this.customer.healthcarePayer = {};
    this.customer.healthcarePayer.name = "";
    this.customer.healthcarePayercode = "";
    this.customer.healthcarePayer.segmentation = "";
    this.customer.healthcarePayer.accommodation = "";
    this.customer.healthcarePayer.network = "";
    this.customer.healthcarePayer.date = "";
    this.customer.healthcarePayer.geography = "";

    this.customer.customFields = [];
  },
};
</script>