import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        tcles: [],
        tclesAccepteds: [],
        tclesNotAccepteds: []
    },
    mutations: {},
    actions: {
        unbindTcles: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("tcles", true);
            }
        ),
        bindTcles: firestoreAction(
            (context, data) => {
                let professional = data.professional;
                let ref = db.collection("tcle");

                if (professional.dev) {
                    ref = ref.where("dev", "==", true);
                } else {
                    ref = ref.where("dev", "==", false);
                }

                context.bindFirestoreRef("tcles", ref, {reset: true});
            }
        ),
        unbindTclesAccepteds: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("tclesAccepteds", true);
            }
        ),
        bindTclesAccepteds: firestoreAction(
            (context, data) => {
                let tcles = data.tcles;
                let ref = db.collection("tcles");
                ref = ref.where("active", "==", true);
                ref = ref.where("consent", "==", true);
                ref = ref.where("tcle", "in", tcles);
                context.bindFirestoreRef("tclesAccepteds", ref, {reset: true});
            }
        ),

        unbindTclesNotAccepteds: firestoreAction(
            ({unbindFirestoreRef}) => {
                unbindFirestoreRef("tclesNotAccepteds", true);
            }
        ),
        bindTclesNotAccepteds: firestoreAction(
            (context, data) => {
                let tcles = data.tcles;
                let ref = db.collection("tcles");
                ref = ref.where("active", "==", false);
                ref = ref.where("consent", "==", false);
                ref = ref.where("tcle", "in", tcles);
                context.bindFirestoreRef("tclesNotAccepteds", ref, {reset: true});
            }
        )
    },
    getters: {
        tclesAccepteds(state) {
            return state.tclesAccepteds;
        },
        tclesNotAccepteds(state) {
            return state.tclesNotAccepteds;
        },
        tcles(state) {
            return state.tcles;
        }
    }
};
