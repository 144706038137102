var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"app"}},[_c('v-main',{staticClass:"mb-6 pa-0 mt-n15"},[_c('v-row',{staticClass:"d-flex flex-row transparent"},[_c('v-col',{staticClass:"d-flex flex-row justify-center transparent",attrs:{"cols":"7"}},[_c('v-card',{staticClass:"d-flex flex-column justify-center",attrs:{"flat":""}},[_c('v-card',{staticClass:"mt-n15",attrs:{"elevation":"0"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.stp),callback:function ($$v) {_vm.stp=$$v},expression:"stp"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.stp > 1,"step":"1","rules":[_vm.errorId]}},[_vm._v(" Identificador do Profissional "),_c('small',[_vm._v(_vm._s(_vm.errorMessageId))])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2","rules":[_vm.errorPIN]}},[_vm._v(" Código de Acesso "),_c('small',[_vm._v(_vm._s(_vm.errorMessagePIN))])])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-text-field-cpf',{staticClass:"mt-1",attrs:{"label":_vm.label,"properties":{
                      required: true,
                      rules: [
                        (v) =>
                          !!v ||
                          'O número do cpf do profissional é obrigatório para acessar o sistema',
                      ],
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      clearable: true,
                      placeholder: 'Digite seu cpf',
                    },"options":{
  outputMask: '###########',
  empty: null,
  applyAfter: false,
}},on:{"focus":function($event){return _vm.focusId()}},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.verifyUser()}}},[_c('v-icon',[_vm._v(" mdi-login-variant ")]),_vm._v(" Verificar ")],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('div',{staticClass:"ma-auto position-relative",staticStyle:{"max-width":"300px"}},[_c('v-otp-input',{attrs:{"length":"5","disabled":_vm.loading},on:{"finish":_vm.login},model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=$$v},expression:"pin"}})],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"pa-15",attrs:{"color":"grey lighten-4 mb-n10 mt-n4 ml-15","min-height":_vm.screenHeight,"max-width":"580","flat":"","tile":"","elevation":"24"}},[_c('v-card-title',{staticClass:"d-flex justify-center align-self-center"},[_c('v-img',{attrs:{"src":require("@/assets/logo/logo.png"),"max-height":"250","max-width":"250"}})],1),_c('v-card-title',{staticClass:"titleCard d-flex justify-center font-weight-thin grey--text text--darken-1"},[_vm._v(" CONFIGURAÇÃO INTEGRADA DE eHEALTH ")]),_c('v-card-title',{staticClass:"titleSystem d-flex justify-center grey--text text--darken-0"},[_c('font-awesome-icon',{staticStyle:{"font-size":"45px"},attrs:{"icon":['fat', 'brain-circuit'],"fixed-width":""}})],1),_c('v-card-title',{staticClass:"titleSystem d-flex justify-center font-weight-thin grey--text text--darken-1"},[_vm._v(" Gestão do Sistema de Inteligência em Saúde - v "+_vm._s(_vm.sisVersion.version.major + "." + _vm.sisVersion.version.minor + "." + _vm.sisVersion.version.patch)+" "),_c('p',{staticClass:"titleBuild text-center grey--text text--lighten-1 caption"},[_vm._v(" Last build: "+_vm._s(_vm.sisVersion.build.date)+" ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }