<template>
  <v-navigation-drawer app :isOpen="mini" :expand-on-hover="true" :permanent="true" color="blue darken-2"
    :hide-overlay="true" dark class="elevation-15">
    <v-list dense nav class="py-0 blue darken-2">
      <v-list-item two-line>
        <v-list-item-avatar class="profile rounded-lg" size="30">
          <img v-if="professional.profilePic" :src="professional.profilePic" />
          <v-icon v-else>mdi-account-circle</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ professional.fullName }}</v-list-item-title>
          <v-list-item-subtitle>{{
              professional.professionalDesc
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mb-4"></v-divider>
      <template>
        <DrawerItem v-for="item in items" :key="item.router" :item="item"></DrawerItem>
      </template>
    </v-list>
    <template v-slot:append>
      <v-list-item link @click="signOut">
        <v-list-item-action>
          <font-awesome-icon :icon="['fal', 'sign-out']" style="font-size: 20px" fixed-width />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { auth, db } from "../../firebase";
import DrawerItem from "./DrawerItem";

export default {
  name: "app-drawer",
  data: () => ({
    available: true,
  }),
  components: {
    DrawerItem,
    script: null,
    body: document.querySelector("zendesk"),
  },
  computed: {
    ...mapGetters({
      professional: "professional",
      items: "items",
    }),
  },
  methods: {
    async setAvailability() {
      this.available = !this.available;
      await db.collection("professionals")
        .doc(this.professional.id).update({ available: this.available }, { merge: true });
    },
    signOut() {
      this.$swal
        .fire({
          title: "Sair do Sistema",
          text: "Você realmente deseja sair do iCareYou?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            auth.signOut().then(() => {
              this.$router.replace({
                name: "Root",
              });
            });
          }
        });
    },
  },
};
</script>

<style>

</style>
