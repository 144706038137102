import Patients from "../../components/functional/patient/Patients.vue";
import PatientNew from "../../components/functional/patient/New.vue";
import PatientsBatch from "../../components/functional/patient/Batch.vue";

export default[
    {
        path: "patients",
        name: "Patients",
        component: Patients,
        meta: {
            requiresAuth: true
        }
    }, {
        path: "patientNew",
        name: "PatientNew",
        component: PatientNew,
        meta: {
            requiresAuth: true
        }
    }, {
        path: "patientsBatch",
        name: "PatientsBatch",
        component: PatientsBatch,
        meta: {
            requiresAuth: true
        }
    }
];
