<template>
  <v-chip v-if="status" color="error" label x-small>Identificador em uso</v-chip>
  <v-chip v-else color="success" label x-small>Ok</v-chip>

</template>

<script>
import { db } from "../../../../firebase";
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    customer: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      status: null,
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      config: "config",
    }),
  },
  methods: {

  },
  async created() {

    let mapRef = db.collection("customer-map").doc(this.customer.userId);
    let mapDoc = await mapRef.get();

    this.status = mapDoc.exists;

  },
};
</script>
